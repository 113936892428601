// @import '~bootstrap';

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
// @import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts" !default;
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";

@import "laravel";

// font-family: 'Poppins', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@import "~slick-carousel/slick/slick.scss";
// @import "~jquery-nice-select/scss/nice-select.scss";

// Components
//

$primary-col: #B2D243;
$secondary-col: #2C2E35;

@import 'components/fonts';
@import 'components/mixins';
@import 'components/components';
@import 'components/buttons';

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  color: $secondary-col;
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: 1300px;
  }
}

h1 {
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 0.7em;
  @include media-breakpoint-down(lg) {
    font-size: 42px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 36px;
  }
}

h2 {
  font-size: 48px;
  //font-weight: bold;
  margin-bottom: 1em;
  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

h3 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 1em;
  @include media-breakpoint-down(lg) {
    font-size: 28px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

h4 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 1em;
  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1em;
}

p {
  color: #505050;
  line-height: 1.5;
  font-size: 16px;
}

.text-link {
  color: $secondary-col;
  text-decoration: underline;
  font-weight: 600;
  transition: all .25s ease-in-out;
  display: inline-block;

  &:hover {
    color: $primary-col;
  }
}

nav.navbar.navbar-expand-lg {
  position: absolute;
  z-index: 6;
  width: 100%;

  &.editable {
    position: static;
    background-color: #2c2e35;
  }

  .navbar-brand {
    img {
      @include media-breakpoint-down(md) {
        max-height: 50px;
      }
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(md) {
      bottom: 100%;
      transition: all .5s ease-in-out;
      position: absolute;
      top: -100%;
      width: 100%;
      background: rgba($secondary-col, .9);
      backdrop-filter: blur(10px);
      left: 0;
      height: 100vh;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      pointer-events: none;
    }

    &.opened {
      top: 0;
      opacity: 1;
      pointer-events: auto;
    }

    .nav-item {
      .nav-link {
        color: white;
        position: relative;
        padding-right: 0;
        padding-left: 0;
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 1.1rem;
        @media (max-width: 1350px)  {
          font-size: 15px;
          margin-right: .7rem;
          margin-left: .7rem;
        }
        @include media-breakpoint-down(md) {
          font-size: 18px;
          margin-top: .8em;
          margin-bottom: .8em;
        }

        &::after {
          @include underlined(.3em, 0, 95%);
          opacity: 0;
        }

        &:hover {
          color: $primary-col;
          @include media-breakpoint-up(lg) {
            color: white;
          }

          &::after {
            @include media-breakpoint-up(lg) {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }

      &.active .nav-link {
        color: $primary-col;
        @include media-breakpoint-up(lg) {
          color: white;
        }

        &::after {
          @include media-breakpoint-up(lg) {
            opacity: 1;
            width: 100%;
          }
        }
      }
    }
  }
}

header {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  min-height: 600px;
  background: $secondary-col;
  @include media-breakpoint-down(md) {
    min-height: auto;
  }

  .slider-btns {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 80px;
    background: url('../images/static/next.svg') no-repeat center center rgba(#E9EAEE, .2);
    background-size: contain;
    cursor: pointer;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    transition: all .25s ease-in-out;
    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 50px;
    }

    &:hover {
      background-color: $primary-col;
    }

    &.next-btn {
      right: 0;
    }

    &.prev-btn {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .slide {
    position: relative;
    width: 100%;
    height: 80vh;
    min-height: 600px;
    z-index: 1;
    @include media-breakpoint-down(md) {
      min-height: auto;
    }
  }

  .floating-image {
    position: absolute;
    width: calc(50vw - 30px);
    height: calc(100% + 150px);
    background: no-repeat center center transparent;
    background-size: cover;
    top: 0;
    right: 0;
    border-bottom-left-radius: 15px;
    max-height: calc(100vh - 40px);
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .floating-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -20%);
    z-index: 3;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 14px;
    width: 100%;

    .btn {
      font-size: 15px; //18px;
      border: 2px solid white;
      @include media-breakpoint-down(md) {
        //font-size: 15px;
        padding: 1rem 1.75rem;
        white-space: nowrap;
      }
    }
  }

  .bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &::after {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, #2C2E35, #2C2E3500 25%, #2C2E3500 50%, #2C2E35);
      content: '';
      display: block;
      left: 0;
      top: 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }

    &.editable {
      pointer-events: auto;

      &::before, &::after {
        display: none;
      }

      .sm_intext, .sm_intext > p {
        position: absolute;
        top: 0;
        left: 2%;
        width: 96%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  h1, h2 {
    display: inline-block;
    font-size: 56px;
    margin-bottom: .7em;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
    }
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }

    strong {
      position: relative;

      &::before {
        @include underlined(.175em, 100%, 90%);
        z-index: -1;
      }
    }
  }

  .subtext, h3 {
    font-size: 36px;
    color: $primary-col;
    font-weight: bold;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  .text-wrap {
    position: absolute;
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 40%; //33%;
    //transform: translateY(50%);
    color: white;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding-right: 35px;
    padding-left: 35px;

    .btn-link {
      color: #fff;
      display: block;
      margin: 0 auto;
      white-space: nowrap;
      width: 0px;
      overflow: hidden;
      padding: 0;
      animation: link-video-anim 500ms 1s forwards ease-in-out;

      &:hover {
        color: $primary-col;
      }

      &:focus {
        box-shadow: none;
      }

      img {
        height: 16px;
      }
    }
  }

  &.subpage {
    min-height: 600px;
    height: 60vh;
    @include media-breakpoint-down(sm) {
      min-height: auto;
    }

    .text-wrap {
      bottom: 105px;
      transform: none;
      @include media-breakpoint-down(sm) {
        bottom: 50px;
      }
    }

    @include media-breakpoint-down(sm) {
      &--poi {
        .text-wrap {
          bottom: 75px;
        }
      }
    }
  }

  &.trip {
    height: auto;
    position: relative;
    z-index: 1;
    background: $secondary-col;
    color: white;
    min-height: auto;
    padding-top: 220px;
    padding-bottom: 120px;
    @include media-breakpoint-down(md) {
      padding-top: 140px;
      padding-bottom: 60px;
    }

    &::before, &::after {
      display: none;
    }

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 60%;
      height: 100%;
      background: url('../images/static/contours.svg') no-repeat center center;
      background-size: cover;
      display: block;
      content: '';
    }

    &::after {
      @include gradient-element(up, 25%);
    }

    > .container {
      position: relative;
      z-index: 4;
    }
  }

  &.home {
    .text-wrap {
      position: relative;
      overflow: hidden;

      h1 {
        position: absolute;
        left: 10000px;
      }
    }

    .home-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
      height: 50vh;
      width: 80vw;
      min-height: 400px;
      opacity: 0;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
      animation-name: fadeIn;
    }
  }
}

@keyframes link-video-anim {
  0% {
    transform: translateX(-65px);
    width: 0;
  }
  100% {
    transform: translateX(0);
    width: 130px;
  }
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.section-p {
  padding-top: 100px;
  padding-bottom: 100px;
  @include media-breakpoint-down(lg) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &--smaller {
    padding-top: 75px;
    padding-bottom: 75px;
    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.grey-bg {
  background: #E9EAEE;
  @include media-breakpoint-down(sm) {
    padding-top: 60px;
  }
}

hr {
  border: 0;
  height: 1px;
  background: url('../images/static/dashed.svg') repeat-x center center;
  margin-top: 65px;
  margin-bottom: 65px;
}

.tripsearch-results {
  padding-top: 20px;
  //padding-bottom: 125px;
  //border-bottom: 1px solid #E9EAEE;
  //@include media-breakpoint-down(md) {
  //  padding-bottom: 60px;
  //}
  //@include media-breakpoint-down(sm) {
  //  padding-bottom: 30px;
  //}
  //@include media-breakpoint-down(xs) {
  //  padding-bottom: 0;
  //}
}

.wbg-down {
  position: relative;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 70%;
    background: white;
    left: 0;
    bottom: 0;
  }
}

.border-top {
  border-color: #E9EAEE !important;
}

.border-bottom {
  border-color: #E9EAEE !important;
}

.map-frame {
  #vylety, #aktivityMapa {
    height: 600px;
    @include media-breakpoint-down(sm) {
      height: 350px;
    }
  }
}

.toggle-holder {
  position: absolute;
  top: 0;
  left: 0;
}

div.m-menu {
  z-index: 10;
  div.m-toggle {
    cursor: pointer;
    position: absolute;
    width: 33px;
    height: 33px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    align-items: center;
    z-index: 55;
    @include media-breakpoint-down(md) {
      display: flex;
    }

    &.active {
      &:hover {
        span.toggle {
          &::after {
            left: 0;
          }

          &::before {
            right: 0;
          }
        }
      }

      span.toggle {
        background: transparent;

        &::after {
          left: 0;
          transform: rotate(45deg);
          width: 100%;
          top: 0;
        }

        &::before {
          right: 0;
          transform: rotate(-45deg);
          width: 100%;
          top: 0;
        }
      }
    }

    &:hover {
      span.toggle {
        &::after {
          left: 50%;
        }

        &::before {
          right: 50%;
        }
      }
    }

    span.toggle {
      width: 33px;
      height: 3px;
      display: block;
      background: white;
      border-radius: 10px;
      top: 50%;
      transform: translate(0, -50%);
      @include transition(0.3s);

      &::before {
        display: block;
        content: '';
        width: 50%;
        height: 3px;
        border-radius: 10px;
        position: absolute;
        top: -8px;
        right: 0;
        background: white;
        @include transition(0.3s);
      }

      &::after {
        display: block;
        content: '';
        width: 50%;
        height: 3px;
        border-radius: 10px;
        position: absolute;
        border: 0;
        left: 0;
        top: 8px;
        background: white;
        @include transition(0.3s);
      }
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.container-xxl {
  max-width: 1680px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media(max-width: 1680px) {
    max-width: 1330px;
  }
}

.header-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sorting li a.active {
  //text-decoration: underline;
  //text-decoration-color: $primary-col;
  //text-decoration-thickness: 2px;
}

.quick-links .toggle-button {
  text-decoration: underline;
}

.article-holder {
  padding-top: 45px;
  padding-bottom: 45px;
  align-items: flex-start;
}

.btn-primary-alt {
  background-color: transparent;
  border-color: $primary-col !important;
  color: #fff;
  &:hover {
    border-color: #fff !important;
  }
}

.floating-button.column {
  flex-direction: column;
}

.underline-anim {
  &::before {
    width: 0%;
    animation: underline-anim 250ms 0.5s forwards ease-in-out;
  }
}

@keyframes underline-anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.g-item {
  .overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgb(0 0 0 / 68%);
    color: #fff;
    border-radius: 10px;
  }
}

.text-limit {
  overflow: hidden;
  max-height: 150px;
  transition: max-height 2000ms ease-in;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2rem;
    position: absolute;
    bottom: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 33%, rgba(255,255,255,0) 100%);
  }

  &.active {
    max-height: 2000px;
    &::after {
      display: none;
    }
  }
}

.btn-text-limit {
  display: block;
  color: #2C2E35;
  max-height: 30px;
  overflow: hidden;
  margin: 0 auto 10px;
  padding: 0;
  transition: max-height 400ms ease-in;

  &.hide {
    max-height: 0;
    margin: 0 auto;
    box-shadow: none;
  }
}

.sm_intext {
  .text-limit {
    max-height: unset;
    overflow: unset;

    &::after {
      display: none;
    }
  }

  .btn-text-limit {
    display: none;
  }
}


.mouse_scroll {
  display: block;
  //margin: 0 auto;
  width: 24px;
  height: 80px;
  //margin-top: 125px;
}


.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 2px solid #b2d243;
  border-bottom: 2px solid #b2d243;
  margin: 0 0 3px 4px;

  width: 18px;
  height: 18px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
  -webkit-animation: mouse-scroll 1s infinite;
  -moz-animation: mouse-scroll 1s infinite;
  animation: mouse-scroll 1s infinite;

}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;

  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;

  animation-delay: .2s;
  animation-direction: alternate;

  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;

  animation-delay: .3s;
  animation-direction: alternate;

  margin-top: -6px;
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}


/*FB EVENTS*/
.events-list {
  .event {
    background: url('../images/static/loading.gif') no-repeat center center;
    background-size: auto;

    .event-info {
      display: flex;
      justify-content: space-between;
      z-index: 3;
      color: white;
      margin-left: 35px;
      margin-right: 35px;
      font-size: 12px;
      margin-bottom: 1em;

      .place {
        padding: 3px 10px;
        border-radius: 3px;
        &.ostatni {
          background: $primary-col;
        }
        &.igi-vratislavice {
          background: white;
          color: black;
        }
        &.vratislavice-101010 {
          background: #ff00ff;
        }
        &.kino-igi {
          background: #d3d3d3;
          color: black;
        }
      }
      .date, .time {
        display: flex;
        white-space: nowrap;
        align-items: center;
        position: relative;
        padding-left: 30px;
        &::before {
          content: '';
          display: block;
          width: 20px;
          aspect-ratio: 1;
          position: absolute;
          left: 0;
          top: 0;
          background-size: contain;
          background-image: url("../images/static/icons/calendar-alt-regular.svg");
        }
      }
      .time::before {
        background-image: url("../images/static/icons/clock-regular.svg");
      }
    }

    .event-desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      overflow: hidden;
      margin-bottom: 30px;
    }
  }
}

html > .peel-svg-clip-element {
  position: absolute;
  top: 0;
  pointer-events: none;
}