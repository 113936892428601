.sorting {
  display: flex;
  padding: 0;
  list-style: none;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: 0 0 75px;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  li {
    a {
      font-weight: 600;
      color: #A5A7AB;
      font-size: 20px;
      margin: 10px 20px;
      position: relative;
      transition: all .5s ease-in-out;
      display: inline-block;
      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
      &::before {
        @include underlined(0.3em, 0, 110%);
        opacity: 0;
      }
      &:hover,&.active {
        color:$secondary-col;
        text-decoration: none;
        &::before {
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}

.sorting-new {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;

  select, input {
    border: 1px solid #A5A7AB;
    border-radius: 10px;
    background: none;
    font-size: 1rem;
    color: #A5A7AB;
    padding: .5rem 1rem;
    margin: 0;
    outline: none;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .filter-options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
  }

  .btn-filter {font-weight: 600;
    color: #A5A7AB;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all .3s ease-in-out;
    display: inline-block;
    &::before {
      @include underlined(0.3em, 0, 110%);
      opacity: 0;
    }
    &:hover,&.active {
      color:$secondary-col;
      text-decoration: none;
      &::before {
        opacity: 1;
        width: 100%;
      }
    }
    &:focus {outline: none; box-shadow: none;}
  }

  #filter-select {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .filter-options {
    display: flex;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .search-container {
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      right: .5rem;
      transform: translateY(-50%);
      z-index: 1;
      width: 1.5rem;
      height: 1.5rem;
      content: "";
      background: url("../../images/static/icons/search.svg?v=4") no-repeat center;
      background-size: contain;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    input, select, .search-container {width: 100%;}
  }
}

.sm_intext[contenteditable="true"] {
  .poi-card {
    .image-holder {
      &::after {
        position: unset;
      }
    }
  }
}

.poi-card {
  background: $secondary-col;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 350px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all .2s ease;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    //content: '';
    background: $primary-col;
    z-index: 2;
    opacity: .4;
  }

  &:hover {
    //background: $primary-col;
    cursor: pointer;
    p {
      color:white;
    }

    transform: scale(1.1);

    /*.image-holder {
      &::after {
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #2C2E35 100%);
      }
    }*/
  }
  a {
    color:currentColor;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  h3 {
    color: white;
    position: relative;
    z-index: 3;
    font-size: 20px;
    font-weight: 600;
    margin-left: 35px;
    margin-right: 35px;
    pointer-events: none;
  }

  p {
    z-index: 3;
    position: relative;
    margin: 0 35px 35px 35px;
    color: #9B9CA2;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
  }

  .trip-content {
    z-index: 3;

    position: relative;
    color: white;
    margin: 0 35px 15px 35px;
  }

  .par-wrap {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .icons {
    z-index: 3;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .text-info {
    padding: 35px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
    h3 {
      font-size: 20px;
      font-weight: 600;
      color:white;
    }
    p {
      margin: 0;
      color:#9B9CA2;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .image-holder {
    position: absolute;
    width: 100%;
    height: 75%;
    top: 0;
    left: 0;
    z-index: 1;
    &::after {
      @include gradient-element(down,100%);
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.trip-card {
  display: block;
  background-color: #fff;
  text-decoration: none;
  color:$secondary-col;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px;
  transition: all .3s ease-in-out;
  margin-bottom: 30px;
  @include transitionfix();
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
  .h-white & {
    &:hover {
      background: white;
    }
  }
  &:hover {
    background:#E9EAEE;
    text-decoration: none;
    color:currentColor;
    .trip-header .image {
      opacity: 1;
      &::after {
        opacity: 0;
      }
    }
  }
  .trip-header {
    border-radius: 10px;
    overflow: hidden;
    background-color: $secondary-col;
    padding: 30px;
    height: 180px;
    position: relative;
    @include transitionfix();
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-size:cover;
      background-position: center center;
      opacity: .5;
      transition: all 0.3s ease-in-out;
      @include transitionfix();
      &::after {
        @include gradient-element(down,100%);
        transition: all 0.3s ease-in-out;
        @include transitionfix();
      }
    }
    h3 {
      font-size: 20px;
      color:white;
      margin-bottom: 0;
      position: absolute;
      left: 30px;
      bottom: 30px;
    }
    .icons {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .trip-content {
    padding: 30px 30px 10px;
  }
}

.par-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  span {
    display: inline-block;
    padding:5px 0;
  }
  .badge {
    font-size: 14px;
    font-weight: bold;
    color:white;
    padding: 8px 13px;
    &-green {
      background:$primary-col;
    }
    &-blue {
      background:#00AEDF;
    }
    &-orange {
      background:#DF8000;
    }
    &-red {
      background:#C5260A;
    }
  }
}
.t-diag {
  position: relative;
  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 110px;
    background:url('../../images/static/light_grey_bg.svg') no-repeat center center white;
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%) rotate(180deg);
  }
  &.grey-bg::before {
    transform: translateY(-50%) rotate(0deg);
  }
}

#instagram {
  .arrow-prev {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 80px;
    background: url('../../images/static/next.svg') no-repeat center center rgba(#E9EAEE, .2);
    background-size: contain;
    cursor: pointer;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    transition: all .25s ease-in-out;
    left: 0;
    transform: translateY(-50%) rotate(180deg);
    z-index: 2;

    @include media-breakpoint-down(md) {
      width: 30px;
      height: 50px;
      background-size: 40px;
      background-position: left center;
      padding-left: 2.25rem;
      background-color: $primary-col;

      &:hover{
        padding-left: 2.75rem;
      }
    }

    @include media-breakpoint-down(lg) {
      left: 15px;
    }

    &:hover {
      background-color: $primary-col;
    }
  }

  .arrow-next {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 80px;
    background: url('../../images/static/next.svg') no-repeat center center rgba(#E9EAEE, .2);
    background-size: contain;
    cursor: pointer;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    transition: all .25s ease-in-out;
    right: calc(0px - ((100vw - 1300px) / 2));
    z-index: 2;

    @include media-breakpoint-down(md) {
      width: 30px;
      height: 50px;
      background-size: 40px;
      background-position: left center;
      padding-left: 2.25rem;
      background-color: $primary-col;

      &:hover{
        padding-left: 2.75rem;
      }
    }

    &:hover {
      background-color: $primary-col;
    }
  }

  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
    .arrow-next {
      right: 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .arrow-next {
      right: 15px;
    }
  }
}

.social-hub {
  //display:flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: calc(100% + ((100vw - 1300px) / 2));
  //overflow: hidden;
  //flex:0 0;

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: calc(100% + ((100vw - 1200px) / 2));
  }

  @include media-breakpoint-down(lg) {
    position: relative;
    margin: 30px 0;
    transform: none;
    width: 100%;
    top: auto;
  }

  .slick-slide {
    padding-right: 10px;

    .s-item {
      //max-width: 290px;
      background:$secondary-col;
      flex:0 0 290px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      display: block;

      @include transitionfix();
      @include media-breakpoint-down(md) {
        // max-width:150px;
        flex:0 0 150px;
      }

      &::before {
        display: block;
        position: relative;
        content: '';
        padding-top: 100%;
      }

      &::after {
        @include gradient-element(down,100%);
        z-index: 1;
        transition: all .25s ease-in-out;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .ico {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        z-index: 2;
        background: url('../../images/static/instagram.svg') no-repeat center center;
        background-size: contain;
      }

      &:hover::after {
        opacity: 0 !important;
      }
    }

    &:last-child {

      padding-right: 0;

      .s-item::after {
        opacity: 1;
        content: "Další…";
        color: white;
        text-align: center;
        padding-top: 50%;
      }
    }
  }
}
.main-points-box {
  border-radius: 10px;
  background: #fff;
  padding: 110px;
  @include media-breakpoint-down(xs) {
    padding: 20px;
    padding-top: 40px;
  }
  a {
    color:currentColor;
  }
  h3 {
    font-size: 36px;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
    strong {
      position: relative;
      &::before {
        @include underlined(.3em,100%,95%);
      }
    }
  }
  span {
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }
  .point {
    margin-top: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    transition: all .25s ease-in-out;
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }

    img {
      width: 50%;
      margin: auto;
    }

    &:hover {
      background: $primary-col;
      color:white;
      text-decoration: none;
    }
  }
}
footer {
  background:#fff;
  padding-top: 65px;
  padding-bottom: 15px;
  font-size: 14px;
  @include media-breakpoint-down(sm) {
    font-size: 13px;
    padding-top: 30px;
    text-align: center;
  }
  strong {
    color:#000;
  }
  p {
    line-height: 34px;
    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }
  }
  @include list-style(14px,20px,$secondary-col,$primary-col);
}
.quick-links {
  background:#E9EAEE;
  // padding: 60px 90px;
  padding: 60px 90px 0px 90px;
  border-radius: 10px;
  @include list-style(16px,16px,#6B6D70,#000);
  position: relative;
  @include media-breakpoint-down(sm) {
    padding: 20px 30px;
  }

  .quick-links-inner {
    overflow: hidden;
    padding-bottom: 60px;
    height: auto;
    max-height: 800px;
    transition: all .3s ease;

    &.closed {
      max-height: 0;
      padding-bottom: 0;
    }
  }

  .toggle-button {
    position: absolute;
    padding-right: 30px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &.open {
      right: 50%;
      transform: translateX(50%);

      &::after {
        background:url('../../images/static/icons/maximize.svg') no-repeat center center;
      }
    }

    &:hover {
      color:$primary-col;
    }

    &::after {
      position: absolute;
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      background:url('../../images/static/icons/minimize.svg') no-repeat center center;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3 {
    margin-bottom: 1.25em;
  }
}

.article-holder {
  display: flex;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include media-breakpoint-down(md) {
    @include make-col(12);
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  &:first-of-type {
    padding-top: 0;
  }
  @media(max-width: 1600px) {
    @include make-container();
  }
  .text-half {
    width: calc(1300px / 2);
    margin-left: calc(50vw - 650px);
    flex:0 0 calc(1300px / 2);
    padding-left:15px;
    padding-right: 15px;
    @media(max-width: 1600px) {
      @include make-col(6);
      margin-left: 0;
      width: auto;
    }
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
  }
  .image-half {
    width: 100%;
    max-width:800px;
    padding-left:15px;
    padding-right: 15px;
    position: relative;
    @media(min-width: 1601px) {
      padding-left:30px;
    }
    @media(max-width: 1600px) {
      @include make-col(6);
    }
    @include media-breakpoint-down(md) {
      @include make-col(12);
    }
    .map-link {
      position: absolute;
      color: $primary-col;
      bottom: -40px;
      left: 50%;
      transform: translateX(-50%);

      &::after {
        position: absolute;
        content: '';
        background: url('../../images/static/icons/external-link-symbol.svg') no-repeat center center;
        background-size: cover;
        width: 13px;
        height: 13px;
        right: -20px;
        top: 5px;
      }
    }
  }
  &:nth-child(even) {
    .text-half {
      order:2;
      @media(min-width: 1601px) {
        margin-left: 0;
        flex-shrink: 0;
        padding-left: 30px;
      }
      @include media-breakpoint-down(md) {
        order: 1;
      }
    }
    .image-half {
      order: 1;
      @media(min-width: 1601px) {
        margin-left: calc(50vw - 815px);
        padding-left: 15px;
        padding-right: 30px;
        width: 100%;
        flex-shrink: 1;
      }
      @include media-breakpoint-down(md) {
        order:2;
      }
    }
  }
}

.thumbs-gallery {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .g-item {
    max-width: calc(25% - (30px / 4));
    flex:0 0 calc(25% - (30px / 4));
    background: $primary-col;
    margin-right: 10px;
    margin-top: 10px;
    // overflow: hidden;
    border-radius: 10px;
    position: relative;
    display: block;
    @include transitionfix();
    &:last-of-type, &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:hover {
      img {
        opacity: .5;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: all .3s ease;
      @include transitionfix();
    }
    &::after {
      content: '';
      display: block;
      padding-top: 70%;
    }
  }
  &--2pr {
    flex-wrap: wrap;
    .g-item {
      max-width: calc(50% - (40px / 2));
      flex:0 0 calc(50% - (40px / 2));
      margin-right: 20px;
      margin-top: 20px;
      @include media-breakpoint-down(xs) {
        max-width: 100%;
        flex:0 0 100%;
        margin-right: 0;
        margin-top: 15px;
      }
    }
  }
}

.vylety-gallery {
  .thumbs-gallery {
    flex-wrap: wrap;

    .g-item {
      max-width: calc(50% - (40px / 2));
      flex:0 0 calc(50% - (40px / 2));
      margin-right: 20px;
      margin-top: 20px;
      @include media-breakpoint-down(xs) {
        max-width: 100%;
        flex:0 0 100%;
        margin-right: 0;
        margin-top: 15px;
      }

      &::before {
        content: attr(data-position);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background: #E9EAEE;
        right: -17px;
        top: 0px;
        transform: translateY(-50%);
        font-size: 20px;
        border: 2px solid white;
        font-weight: normal;
        z-index: 2;
        color: black;
      }
    }
  }
}

.image-flip {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor:pointer;
  .peel-top > img {


    min-height: 300px;
    width: 100%;
    object-fit: cover;

    @include media-breakpoint-up(lg) {
      height: 475px;
    }
  }

  img {
    width: 100%;
  }

  .smap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .peel-layer {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    transform-origin: top left;
    border-radius: 10px;
    overflow: hidden;
    &.peel-top {
      position: relative;
    }
  }
  .peel-bottom {
    background: transparent;
    pointer-events: none;
  }

  .peel-back {
    background: url('../../images/static/iframe-sample.jpg');
  }

  .peel-svg-clip-element {
    position: absolute;
    top: -10000px;
    left: -10000px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .peel-ready {
    opacity: 1;
  }
}

.tips-filter {
  border-top: 1px solid #E9EAEE;
  border-bottom: 1px solid #E9EAEE;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 14px;
  .filters {
    display:flex;
    flex-wrap: wrap;
    > * {
      margin-left:5px;
      margin-right: 5px;
    }
  }
}

.backlink {
  display: inline-block;
  padding-left: 30px;
  position: relative;
  color:#E9EAEE;
  transition: all .25s ease-in-out;
  font-size: 18px;
  @include media-breakpoint-down(sm) {
    font-size:16px;
  }
  &:hover {
    color:$primary-col;
    text-decoration: none;
    padding-left: 40px;
  }
  &::before {
    width: 18px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: block;
    background:url('../../images/static/back-arrow.svg') no-repeat center center;
    background-size: contain;
  }
}

.maplink {
  display: flex;
  color:currentColor;
  align-items: center;
  transition: all .25s ease-in-out;
  &:hover {
    text-decoration: none;
    color:$primary-col;
    img {
      opacity: .5;
    }
  }
  img {
    margin-right: 15px;
    transition: all .25s ease-in-out;
  }
}

.path-holder {
  padding-left: 55px;
  position: relative;
  &::before {
    width: 5px;
    height: 100%;
    position: absolute;
    display: block;
    content: '';
    left: 20px;
    top: 0;
    background:url('../../images/static/dashed-vertical.svg') repeat-y center top;
  }
  h4 {
    margin-top: 2em;
    position: relative;
    &::after {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background:#E9EAEE;
      content: '1';
      left: -52px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 20px;
      border:2px solid white;
      font-weight: normal;
      z-index: 2;
      @include media-breakpoint-down(sm) {
        font-size: 18px;
      }
    }
  }
  p:last-of-type {
    position: relative;
    &::before {
      position: absolute;
      width:  50px;
      height: calc(100% + 20px);
      top: -20px;
      left: -55px;
      content: '';
      display: block;
      background: white;
      z-index: 1;
    }
  }
  @for $i from 1 through 20 {
    h4:nth-of-type(#{$i})::after {
      content: '#{$i}';
    }
  }
}