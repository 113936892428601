.btn {
  font-size: 14px;
  padding:0.75rem 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.btn-large {
  padding:1.25rem 2.5rem;
}
.btn-primary {
  @include button-variant($primary-col,$primary-col);
  color:$secondary-col;
}