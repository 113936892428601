@mixin underlined($height,$width,$top) {
  position: absolute;
  @if $height {
    height: $height;
  } @else {
    height: 0.3em;
  }
  content: '';
  display: block;
  top: $top;
  left: 0;
  background: url('../../images/static/uline-shp.svg') no-repeat center center;
  transition: all  .5s ease-in-out;
  width: $width;
}

@mixin gradient-element($dir,$height) {
  position: absolute;
  width: 100%;
  height: $height;
  content: '';
  display: block;
  left: 0;
  top: 0;
  z-index: 3;
  @if $dir == up {
    transform: rotate(180deg);
    background-image: linear-gradient(180deg, rgba(44,46,53,0.00) 0%, #2C2E35 100%);
  } @else if $dir == down {
    background-image: linear-gradient(180deg, rgba(44,46,53,0.00) 0%, #2C2E35 100%);
  }
}

@mixin list-style($fs,$lh,$color,$h-color) {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color:$color;
      margin-bottom: $lh;
      font-size: $fs;
      a {
        color:currentColor;
        &:hover {
          color:$h-color;
        }
      }
    }
  }
}

@mixin transitionfix() {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0)
}